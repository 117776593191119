import React, { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import styles from './Navigation.module.scss';

import logo from './logo';

const scrollTo = (event, el) => {
  event.preventDefault();
  if (el === 'TOP') {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    return;
  }

  const element = document.getElementById(el);
  const navOffset = 70;
  const elementPosition = element.getBoundingClientRect().top;
  const offsetPosition = elementPosition - navOffset;

  window.scrollBy({
    top: offsetPosition,
    behavior: 'smooth',
  });
};

const Navigation = ({ openModal, showNavbar = true }) => {
  const [ref, inView, entry] = useInView({
    threshold: 1,
  });

  const [isAbove, setAbove] = useState(false)


  useEffect(() => {
    if (entry) {
      setAbove(entry.boundingClientRect.y < 0);
    }
  }, [inView]);


  let sticky = inView || isAbove;

  return (
    <>
      <nav className={`${styles.Navigation} ${sticky ? styles.shadow : ''}`}>
        <div className={styles.navContainer}>
          <div>
            <img src={logo} alt="Drogevate logo" className={styles.logo} />
          </div>
          <ul className={styles.menu}
              style={{ visibility: showNavbar !== undefined && showNavbar=== true? 'visible': 'hidden'}}
          >
            <li className={styles.item}>
              <a
                href="/"
                onClick={event => {
                  scrollTo(event, 'TOP');
                }}
              >
                Home
              </a>
            </li>
            <li className={styles.item}>
              <a
                href="#contact"
                onClick={event => {
                  scrollTo(event, 'contact');
                }}
              >
                Contact
              </a>
            </li>
            <li>
              <button className={styles.demo} onClick={() => openModal(true)}>
                Request Demo
              </button>
            </li>
          </ul>
        </div>
      </nav>
      <div className={styles.interactionObserver} ref={ref}></div>
    </>
  );
};

export default Navigation;
