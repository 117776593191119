import React from 'react';
import styles from './Footer.module.scss';

import logo from '../../images/logo_full.svg';
import facebookIcon from '../../images/facebook.svg';
import linkedinIcon from '../../images/linkedin.svg';
import twitterIcon from '../../images/twitter.svg';

const Footer = () => {
  return (
    <footer className={styles.Footer}>
      <div className={styles.footerContainer}>
        <img src={logo} alt="Drogevate Solutions logo" className={styles.logo} />
        <div className={styles.socialIcons}>
          <a
            href="https://www.facebook.com/activatingdatafromdocuments/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={facebookIcon} alt="Facebook logo" className={styles.icon} />
          </a>
          <a
            href="https://twitter.com/drogevate"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={twitterIcon} alt="Twitter logo" className={styles.icon} />
          </a>
          <a
            href="https://www.linkedin.com/company/drogevate/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={linkedinIcon} alt="LinkedIn Logo" className={styles.icon} />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
